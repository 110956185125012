import {
  Table as TB,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Button,
  Typography,
  Checkbox,
} from "@mui/material";
import { Delete as DEL } from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import { styled } from "@mui/system";
import { Card, useAuth } from "../../sdk";
import UserModal from "./CreateUserModal";
import DateModal from "./DateModal";

const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    background-color: white;
    border: none;
    text-align: left;
    padding: 10px 10px;
  }
`;
const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 16px/20px Roboto;
    color: #333333;
    border: none;
    padding: 4px 10px;
    text-align: left;
    min-width: 100px;
    vertical-align: top;
  }
`;

const Tr = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(48, 81, 221,0.09)",
  },
}));

const Thead = styled(TableHead)``;
const Tbody = styled(TableBody)``;

const HotelName = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 100%;
`;

const UserCard = styled(Card)`
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  margin-top: 25px;
  margin-left: 5px;
  padding: 20px;
  padding-top: 0px;
`;

const Header = styled(Box)`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 52px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  position: fixed;
  z-index: 999;
`;

const AddUserContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 35px;
  background: rgb(48, 111, 188) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-top: -147px;
`;

const AddUserBtn = styled(Button)`
  width: auto;
  border: none;
  background-color: rgb(48, 111, 188);
  padding: 5px 10px;
  box-shadow: none;
  cursor: pointer;
  text-align: right;
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #fff;
  display: flex;
  align-items: flex-end;
  position: relative;
  outline: none;
`;

const DelIcon = styled(DEL)`
  width: 60px;
  &:hover {
    cursor: pointer;
  }
`;

const styleHotelModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid rgb(48, 111, 188)",
  boxShadow: 20,
  p: 4,
};

const Dot = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #141641;
  margin-right: 5px;
`;

export default function UserListTable({
  userList,
  chains,
  hotels,
  setError,
  setLoading,
  getUser,
  selectedChain,
  allRoles,
  setShouldBlockNavigation,
  setUserId,
  setUserNameToDelete,
  setNetworkMsg,
  setOpen,
  setFilterInput,
  selectedEmails,
  handleCheckboxChange,
  downloadButton,
  setDownloadButton,
  setSelectedEmails,
  dateDialog,
  setDateDialog,
  userName,
  setUserName,
}) {
  const { permission, authFetch } = useAuth();
  const [openAddUser, setOpenAddUser] = useState(false);
  const [isInUpdateMode, setIsInUpdateMode] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [openMoreHotel, setOpenMoreHotel] = React.useState(false);

  const [selectedHotelArray, setSelectedHotelArray] = useState([]);
  const handleOpenMoreHotels = (hotelArr) => {
    setSelectedHotelArray(hotelArr);
    setOpenMoreHotel(true);
  };

  const handleCloseMoreHotels = () => {
    setSelectedHotelArray([]);
    setOpenMoreHotel(false);
  };
  let userRightsUpdate = null,
    addUser = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "userRightsUpdate") {
        userRightsUpdate = permission[key];
      }
      if (permission[key].name === "addUser") {
        addUser = permission[key];
      }
    }
  }

  const handleUpdateButton = useCallback(
    (info) => {
      setIsInUpdateMode(true);
      setUpdateData(info);
      setOpenAddUser(true);
      setFilterInput({search: "", selectedRolls:[], selectedHotels:[]});
    },
    [setFilterInput]
  );

  const exportFile = useCallback(async () => {
    const { post } = await authFetch({
      path: `/user/rate-shop-log`,
    });

    const body = {
      emailList: selectedEmails,
    };

    const response = await post(body);
    const blob = await response.response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Rateshop-Logs.xlsx`);
    document.body.appendChild(link);
    link.click();
  }, [authFetch, selectedEmails]);

  function getRole(id) {
    const role = allRoles?.find((role) => role.id === id);
    return role?.description;
  }

  return (
    <UserCard>
      <Header>
        <AddUserContainer sx={{marginRight: addUser? 0 : "120px"}}>
          <AddUserBtn
            disabled={downloadButton && selectedEmails?.length === 0}
            onClick={() => {
              if (downloadButton) {
                setDateDialog(true);
              } else {
                setDownloadButton((prev) => !prev);
              }
            }}
          >
            {downloadButton ? "Download" : "Export Logs"}
          </AddUserBtn>
        </AddUserContainer>
        {addUser && (
          <AddUserContainer
            sx={{
              marginRight: "120px",
            }}
          >
            <AddUserBtn
              onClick={() => {
                setIsInUpdateMode(false);
                setUpdateData(null);
                setOpenAddUser(true);
              }}
            >
              Add User
            </AddUserBtn>
          </AddUserContainer>
        )}
      </Header>
      {userList?.length ? (
        <TableContainer style={{ height: "94%", minWidth: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <Thead>
              <Tr>
                {downloadButton && <Th></Th>}
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Username</Th>
                <Th>Hotels</Th>
                <Th>Role</Th>
                {userRightsUpdate && <Th>Edit</Th>}
                {addUser && <Th style={{ textAlign: "center" }}>Delete</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {userList?.map((info, index) => (
                <Tr key={index}>
                  {downloadButton && (
                    <Checkbox
                      size="small"
                      checked={selectedEmails?.some(
                        (row) => row === info?.email
                      )}
                      onChange={() =>
                        handleCheckboxChange(info?.email, info?.username)
                      }
                    />
                  )}
                  <Td>{info?.name}</Td>
                  <Td>{info?.email}</Td>
                  <Td>{info?.username}</Td>
                  <Td
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      maxWidth: "300px",
                    }}
                  >
                    {info?.hotelList ? (
                      info?.hotelList?.length <= 3 ? (
                        info?.hotelList.map((hotel, index) => (
                          <HotelName
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Dot></Dot>
                            {hotel.name}
                          </HotelName>
                        ))
                      ) : (
                        <>
                          {info?.hotelList?.slice(0, 3).map((hotel, index) => (
                            <HotelName
                              key={index}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Dot></Dot>
                              {hotel.name}
                            </HotelName>
                          ))}

                          <Button
                            variant="text"
                            onClick={() => handleOpenMoreHotels(info.hotelList)}
                            sx={{
                              textTransform: "none",
                              lineHeight: 1,
                              p: 0,
                              pl: "11px",
                            }}
                          >
                            more...
                          </Button>
                        </>
                      )
                    ) : (
                      "-"
                    )}
                  </Td>
                  <Td>{info.roleID ? getRole(info.roleID) : "-"}</Td>
                  {userRightsUpdate && (
                    <Td>
                      <Button
                        sx={{
                          height: "30px",
                          width: "75px",
                          textTransform: "none",
                        }}
                        variant="contained"
                        onClick={() => handleUpdateButton(info)}
                      >
                        Update
                      </Button>
                    </Td>
                  )}
                  {addUser && (
                    <Td style={{ textAlign: "center", paddingLeft: "16px" }}>
                      <DelIcon
                        style={{ fontSize: 22 }}
                        onClick={() => {
                          setShouldBlockNavigation(true);
                          setUserId(info.id);
                          setUserNameToDelete(info.name);
                        }}
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Modal open={openMoreHotel} onClose={handleCloseMoreHotels}>
            <Box sx={styleHotelModal} maxHeight={400} overflow="scroll">
              <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                List of all hotels
              </Typography>
              {selectedHotelArray &&
                selectedHotelArray.map((hotel, index) => (
                  <HotelName
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 1,
                    }}
                  >
                    <Dot></Dot>
                    {hotel.name}
                  </HotelName>
                ))}
            </Box>
          </Modal>
        </TableContainer>
      ) : (
        <Stack
          textAlign="center"
          marginTop="40px"
          color="#747474"
          fontFamily="Roboto"
        >
          <Box>No such user exists</Box>
          <Box flex={1}>
            <Box component="img" src="/assets/ShowUserInitial.svg" />
          </Box>
        </Stack>
      )}
      <Modal
        open={openAddUser}
        onClose={(_, reason) =>
          reason === "backdropClick" && setOpenAddUser(true)
        }
      >
        <div>
          <UserModal
            setOpenAddUser={setOpenAddUser}
            isInUpdateMode={isInUpdateMode}
            updateData={updateData}
            chains={chains}
            hotels={hotels}
            setError={setError}
            setLoading={setLoading}
            getUser={getUser}
            selectedChain={selectedChain}
            allRoles={allRoles}
            setOpen={setOpen}
            setNetworkMsg={setNetworkMsg}
          ></UserModal>
        </div>
      </Modal>
      <DateModal
        dateDialog={dateDialog}
        setDateDialog={setDateDialog}
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
        setUserName={setUserName}
        userName={userName}
        setDownloadButton={setDownloadButton}
        downloadButton={downloadButton}
      />
    </UserCard>
  );
}
